import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
// Utilities
import kebabCase from "lodash/kebabCase"
import Img from "gatsby-image"
import SEO from "../components/seo"

class Post extends React.Component {
  render() {
    const { data } = this.props
    const project = this.props.data.markdownRemark
    const images = data.allFile
    const tags = project.frontmatter.tags
    return (
      <Layout
        title={project.frontmatter.title}
        subtitle={project.html}
      >
        <SEO
          title={project.frontmatter.title}
          keywords={[`3D`, `Visualisaties`, `Projecten`, `Animaties`]}
        />
        <div className="project-tags is-pulled-right">
          <div className="tags">
            {tags.map(tag => (
              <Link
                to={`/tags/${kebabCase(tag)}/`}
                key={tag}
                className="tag is-info"
              ><span>{tag}</span>
              </Link>
            ))}
          </div>
        </div>

        <section className="section">

          {images.nodes.map(image => (
            <Img
              alt={image.name}
              key={image.childImageSharp.fluid.src}
              fluid={image.childImageSharp.fluid}
              style={{ margin: '1rem 0' }}
            />
          ))}
        </section>
      </Layout>
    )
  }
}

export default Post

export const query = graphql`
  query ($path: String!) {
    markdownRemark(fields: {slug: { eq: $path } }) {
      frontmatter {
        title
        videoSrcURL
        videoTitle
        tags
      }
      html
    }
    allFile(filter: {extension: {eq: "jpg"}, absolutePath: { regex: $path } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
